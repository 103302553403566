import { Redirect, Route } from "react-router-dom";
import {
    IonIcon,
    IonLabel,
    IonPage,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact,
} from "@ionic/react";
import "./index.scss";

import Alerts from "./pages/alerts";
import Notifications from "./pages/Notifications";
import Profile from "./pages/profile";
import Map from "./pages/Map";

import { home } from "ionicons/icons";

import GroupDetails from "./pages/groups/pages/groupdetails";
import { useNotificationService, useConfigureShieldWebAPI } from "../../hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { appSync } from "../../redux/slices/app";
import MapIcon from "./../../assets/icons/map.svg";
import MapIcon2 from "./../../assets/icons/map2.svg";
import AlertIcon from "./../../assets/icons/alert.svg";
import AlertIcon2 from "./../../assets/icons/alert2.svg";
import MembersIcon from "./../../assets/icons/MembersIcon.svg";
import MembersIcon2 from "./../../assets/icons/MembersIcon2 .svg";
import GroupsIcon from "./../../assets/icons/groups.svg";
import GroupsIcon2 from "./../../assets/icons/groups2.svg";
import Incidents from "./pages/incidents";
import ProfileProvider from "../../providers/Profile/ProfileProvider";
import DeleteAccount from "./pages/DeleteAccount";
import CompleteIncidente from "./pages/CompleteIncidente";
import { useSelector } from "react-redux";
import {
    selectCanManageGroups,
    selectDependencyUser,
    selectGobUser,
} from "../../redux/slices/auth";
import MeGroups from "./pages/groups/pages/MeGroups";
import {
    selectHaveIGroups,
    selectMeGroupsForIncidents,
} from "../../redux/slices/entities/groups";
import CreateGroups from "./pages/groups/pages/CreateGroups";
import EditGroup from "./pages/groups/pages/EditGroup";
import EditAccount from "./pages/EditAccount";
import ChangePassc from "./pages/ChangePassword";
import ChangeNumber from "./pages/ChangePhoneNumber";
import MembersMap from "./pages/MembersMap";
import MembersGroupMap from "./pages/MembersGroupMap";
import MapSolved from "./pages/profile/pages/MapSolved";
import MapUnsolved from "./pages/profile/pages/MapUnsolved";
import MapSolvedGroup from "./pages/groups/pages/MapSolvedGroup";
import MapUnsolvedGroup from "./pages/groups/pages/MapUnsolvedGroup";
import ShowMapIncident from "./pages/incidents/pages/ShowMapIncident";
import GrupUserExist from "./pages/groups/pages/GrupUserExist";
import PermissionsPage from "../Permissions";
import DependenciesMap from "./pages/DependenciesMap";
import CreateMember from "./pages/groups/pages/CreateMember";
import CreateSuperShields from "./pages/profile/pages/CreateSuperShields";
import PanicButton from "../../components/PanicButton/PanicButton";
import { MEMBERSHIPS } from "../../constants";
import { selectMeActivedMemberships } from "../../redux/slices/entities/memberships";

setupIonicReact();

interface Props {
    loggedIn: boolean;
}

const Tabs: React.FC<Props> = ({ loggedIn }) => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    useConfigureShieldWebAPI();
    /**
     * shared state
     */
    const groups = useSelector(selectMeGroupsForIncidents);
    const hasGroups = useSelector(selectHaveIGroups);
    const canManageGroups = useSelector(selectCanManageGroups);
    const isGovernor = useSelector(selectGobUser);
    const isDependency = useSelector(selectDependencyUser);
    const myMemberships = useSelector(selectMeActivedMemberships);

    /**
     * Determina si tab de grupos esta desactivado
     * Si no tiene grupos y si no puede administrar grupos
     */
    const disabledGroupsTab = !hasGroups && !canManageGroups;

    /**
     * Local state
     */
    const [tabSeleccionada, setTabSeleccionada] = useState<string>("alerts");

    const handleTabChange = (tab: any) => {
        setTabSeleccionada(tab);
    };

    useEffect(() => {
        dispatch<any>(appSync());
    }, []);

    useNotificationService();

    return (
        <ProfileProvider>
            <IonPage>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route
                            path="/app/permissions"
                            component={PermissionsPage}
                        />
                        <Route
                            exact
                            path="/app/alerts/:id/complete"
                            render={() => <CompleteIncidente />}
                        />
                        <Route
                            exact
                            path="/app/alerts"
                            render={() => <Alerts />}
                        />
                        <Route
                            exact
                            path="/app/members"
                            render={() => <DependenciesMap />}
                        />
                        <Route
                            exact
                            path="/app/incidents"
                            render={() => <Incidents />}
                        />
                        <Route
                            exact
                            path="/app/incidents/map"
                            render={() => <ShowMapIncident />}
                        />
                        <Route exact path="/app/map" render={() => <Map />} />
                        <Route
                            exact
                            path="/app/notifications"
                            render={() => <Notifications />}
                        />
                        <Route
                            exact
                            path="/app/groups/:id/incidets-Solved"
                            render={() => <MapSolvedGroup />}
                        />
                        <Route
                            exact
                            path="/app/groups/:id/incidets-unsolved"
                            render={(props) => <MapUnsolvedGroup />}
                        />
                        <Route
                            exact
                            path="/app/groups/details/:id/edit"
                            render={(props) => <EditGroup />}
                        />

                        <Route
                            exact
                            path="/app/groups/details/:id"
                            render={() => <GroupDetails />}
                        />
                        <Route
                            exact
                            path="/app/groups/details/:id/members-group-map"
                            render={() => <MembersGroupMap />}
                        />
                        <Route
                            exact
                            path="/app/groups/create"
                            render={(props) => <CreateGroups />}
                        />
                        <Route
                            exact
                            path="/app/groups/create/user-exist"
                            render={() => <GrupUserExist />}
                        />
                        <Route
                            exact
                            path="/app/groups/:id/Create-member"
                            render={(props) => <CreateMember />}
                        />
                        <Route
                            exact
                            path="/app/groups"
                            render={(props) => {
                                return canManageGroups ? (
                                    <MeGroups />
                                ) : (
                                    <Redirect
                                        to={
                                            "/app/groups/details/" +
                                            groups[0]?.group_id
                                        }
                                    />
                                );
                            }}
                        />
                        <Route
                            exact
                            path="/app/profile/edit"
                            render={() => <EditAccount />}
                        />
                        <Route
                            exact
                            path="/app/profile/members-map"
                            render={() => <MembersMap />}
                        />
                        <Route
                            exact
                            path="/app/profile/incidets-Unsolved"
                            render={() => <MapUnsolved />}
                        />
                        <Route
                            exact
                            path="/app/profile/incidets-Solved"
                            render={() => <MapSolved />}
                        />
                        <Route
                            exact
                            path="/app/profile/delete-account"
                            render={() => <DeleteAccount />}
                        />
                        <Route
                            exact
                            path="/app/profile"
                            render={() => <Profile />}
                        />
                        <Route
                            exact
                            path="/app/profile/ChangePassword"
                            render={() => <ChangePassc />}
                        />
                        <Route
                            exact
                            path="/app/profile/ChangePhone"
                            render={() => <ChangeNumber />}
                        />
                        <Route
                            exact
                            path="/app/profile/Create-Admin"
                            render={() => <CreateSuperShields />}
                        />
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom" className="shield-tab-bar">
                        <IonTabButton
                            tab="alerts"
                            href="/app/alerts"
                            onClick={() => handleTabChange("alerts")}>
                            <IonIcon src={home}></IonIcon>
                            <IonLabel>Inicio</IonLabel>
                        </IonTabButton>
                        <IonTabButton
                            tab="map"
                            href="/app/map"
                            className={` ${
                                myMemberships[0]?.type ===
                                MEMBERSHIPS.ADMINISTRATOR
                                    ? "tab-class"
                                    : "tab-class2"
                            }`}
                            onClick={() => handleTabChange("map")}>
                            <IonIcon
                                src={
                                    tabSeleccionada !== "map"
                                        ? MapIcon
                                        : MapIcon2
                                }></IonIcon>
                            {isGovernor || isDependency ? (
                                <IonLabel>Incidentes</IonLabel>
                            ) : (
                                <IonLabel>Mapa</IonLabel>
                            )}
                        </IonTabButton>
                        {myMemberships[0]?.type ==
                            MEMBERSHIPS.ADMINISTRATOR && (
                            <IonTabButton className="middle-btn">
                                <PanicButton />
                            </IonTabButton>
                        )}
                        {isGovernor || isDependency ? (
                            <IonTabButton
                                tab="members"
                                href="/app/members"
                                className={` ${
                                    myMemberships[0]?.type ===
                                    MEMBERSHIPS.ADMINISTRATOR
                                        ? "tab-class"
                                        : "tab-class2"
                                }`}
                                onClick={() => handleTabChange("members")}>
                                <IonIcon
                                    src={
                                        tabSeleccionada !== "members"
                                            ? MembersIcon2
                                            : MembersIcon
                                    }></IonIcon>
                                <IonLabel>Miembros</IonLabel>
                            </IonTabButton>
                        ) : (
                            <IonTabButton
                                tab="incidents"
                                href="/app/incidents"
                                className={` ${
                                    myMemberships[0]?.type ===
                                    MEMBERSHIPS.ADMINISTRATOR
                                        ? "tab-class"
                                        : "tab-class2"
                                }`}
                                onClick={() => handleTabChange("incidents")}>
                                <IonIcon
                                    src={
                                        tabSeleccionada !== "incidents"
                                            ? AlertIcon
                                            : AlertIcon2
                                    }></IonIcon>
                                <IonLabel>Incidentes</IonLabel>
                            </IonTabButton>
                        )}

                        <IonTabButton
                            tab="groups"
                            href="/app/groups"
                            onClick={() => handleTabChange("groups")}
                            disabled={disabledGroupsTab}>
                            <IonIcon
                                src={
                                    tabSeleccionada !== "groups"
                                        ? GroupsIcon
                                        : GroupsIcon2
                                }></IonIcon>
                            <IonLabel>Grupos</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonPage>

            {/*       {isKeyboardOpen ? null : <BottomSheet />} */}
        </ProfileProvider>
    );
};

export default Tabs;
