import {
    IonButton,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonCol,
    IonRow,
    IonPage,
    useIonLoading,
    IonText,
    IonRadioGroup,
    IonRadio,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonPopover,
    IonDatetime,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonTitle,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import {
    callOutline,
    calendarOutline,
    mailOutline,
    eyeOutline,
    eyeOffOutline,
    chevronDownOutline,
    chevronUpOutline,
} from "ionicons/icons";
import { useDispatch } from "react-redux";
import { login, signup } from "../../redux/slices/auth";
import { useFeedback } from "../../hooks";
import { DateTime } from "luxon";
import "./Register.scss";
import MapComponent from "./pages/MapComponent";
import { useHistory } from "react-router";
import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";

import { useMaskito } from "@maskito/react";
import escudoWebAPI from "../../services/escudoWebAPI";
import WelcomeModal from "./pages/Modal/pages/WelcomeModal";
import LocationPermissionModal from "./pages/Modal";
import arrowBackButton from "./../../assets/icons/arrow-back-button.svg";
import userProfile from "./../../assets/icons/user-profile.svg";
import marketInput from "../../assets/icons/market-input.svg";
import { State } from "../../models/state";
import { Municipality } from "../../models/municipality";
import { City } from "../../models/city";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

const Register: React.FC = () => {
    /**
     * hooks
     */
    const dispatch = useDispatch();
    const [present, disminss] = useIonLoading(); // Loading state management
    const feedbackApp = useFeedback(); // Feedback component
    const history = useHistory(); // Navigation history

    /**
     * Local state
     */

    // let a =
    //     DateTime.now().minus({ years: 19 }).toFormat("yyyy") +
    //     "-01-01 00:00:00";

    // let initialDate = DateTime.fromSQL(a).toFormat("yyyy-MM-dd");

    const [user, setUser] = useState({
        name: "",
        last_name: "",
        second_last_name: "",
        cellphone: "",
        email: "",
        inside_number: "",
        outside_number: "",
        street: "",
        zipcode: "",
        date_birth: "",
        gender: "",
        password: "",
        password_confirmation: "",
        latitude: 0,
        longitude: 0,
        colony: "",
        address_city_id: null,
    });

    /**
     * State variables
     *
     */
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedGender, setSelectedGender] = useState<string>("");
    const [Selectedterms, SetSelectedTerms] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    /**
     * Estado para los listados de ciudades
     */
    const [states, setAllStates] = useState([]);
    const [allMunicipalities, setAllMunicipalities] = useState([]);
    const [allCities, setAllCities] = useState([]);

    /**
     * Estado para las localidades seleccionadas
     */
    const [selectedState, setSelectedState] = useState<State | null>(null);
    const [selectedMunicipality, setSelectedMunicipality] =
        useState<Municipality | null>(null);
    const [selectedCities, setSelectedCities] = useState<City | null>(null);

    /**
     * Recuperacion de los estados
     */
    useEffect(() => {
        const get = async () => {
            const state = await escudoWebAPI
                .getAllStates()
                .then((res: any) => res.data.data);
            setAllStates(state);
        };

        get();
    }, []);

    useEffect(() => {
        if (selectedState !== null) {
            const getMunicipalities = async () => {
                const municipalities = await escudoWebAPI
                    .getMunicipalitiesByState(selectedState.state_id)
                    .then((res: any) => res.data.data);
                setAllMunicipalities(municipalities);
            };

            getMunicipalities();
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedMunicipality !== null) {
            const getCitys = async () => {
                const cities = await escudoWebAPI
                    .getCityByMunicipalite(selectedMunicipality.municipality_id)
                    .then((res: any) => res.data.data);
                setAllCities(cities);
            };

            getCitys();
        }
    }, [selectedMunicipality]);

    /**
     * Actions for registration
     *
     */
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const phoneMask = useMaskito({
        options: {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        },
    });

    /**
     * accion para la obtencion de la localiazacion
     */

    const getLocaion = async () => {
        const locationPermissions = await Geolocation.checkPermissions();

        if (Capacitor.getPlatform() === "web") {
            if (locationPermissions.location === "denied") {
                setIsModalOpen(true);
            }
        } else {
            if (locationPermissions.location == "granted") {
                let permisionReuqest = await Geolocation.requestPermissions();

                let { location } = permisionReuqest;
            }
        }

        const position = await Geolocation.getCurrentPosition();

        let { latitude, longitude } = position.coords;

        return {
            latitude,
            longitude,
        };
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { latitude, longitude } = await getLocaion();
                setUser((prevUser) => ({
                    ...prevUser,
                    latitude,
                    longitude,
                }));
            } catch (error) {
                setIsModalOpen(true);
            }
        };

        fetchData();
    }, []);

    /**
     * Input change handlers
     *
     */
    const onHandlerInputChange = (customeEvent: CustomEvent) => {
        let { event, value } = customeEvent.detail;

        let name = event.target.name;

        if (name === "zipcode" && name.length > 5) {
            value = value.slice(0, 5);
        }

        setUser({ ...user, [name]: value });
    };

    const handleRadioChange = (customeEvent: CustomEvent) => {
        const selectedValue = customeEvent.detail.value;
        setSelectedGender(selectedValue);

        setUser({ ...user, gender: selectedValue });
    };

    const handleRadioTerms = (e: CustomEvent) => {
        SetSelectedTerms(e.detail.value);
    };

    /**
     * maneja el cambio de la fecha de nacimiento
     */
    const handleDateChange = (customEvent: CustomEvent) => {
        const selectedDate = customEvent.detail.value;

        if (DateTime.fromISO(selectedDate).isValid) {
            const formattedDate =
                DateTime.fromISO(selectedDate).toFormat("yyyy-MM-dd");

            setUser({ ...user, date_birth: formattedDate });
        }
    };

    /**
     * Maneja la seleccion de un estado
     */
    const handleStateChange = (CustomeEvent: CustomEvent) => {
        const newState = CustomeEvent.detail.value;

        setSelectedState(newState);
        setSelectedMunicipality(null);
        setSelectedCities(null);
    };

    /**
     * Manejjo de la selecion del municipo
     */
    const onHandlerMunicipalityChange = (e: CustomEvent) => {
        setSelectedMunicipality(e.detail.value);
        setSelectedCities(null);
    };

    /**
     * Manejo de la selecion de una ciudad
     */
    const onHandlerCityChange = (e: CustomEvent) => {
        const selectedCity = e.detail.value;

        setSelectedCities(selectedCity);

        setUser({ ...user, address_city_id: selectedCity.city_id });
    };

    /**
     * Validaciones de los campos
     *
     */

    const isNameValid = () => ({
        isValid: user.name !== "",
        message: "El nombre es requerido",
    });

    const isLastNameValid = () => ({
        isValid: user.last_name !== "",
        message: "El apellido paterno es requerido",
    });

    const isCellphoneValid = () => ({
        isValid: user.cellphone !== "" && user.cellphone.length === 10,
        message: "El teléfono es requerido",
    });
    const isDateBirthValid = () => ({
        isValid: user.date_birth !== "",
        message: "El año de nacimiento es requerido",
    });

    const isStreetValid = () => ({
        isValid: user.street !== "",
        message: "La calle del usuario es requerida",
    });

    const isExteriorNumberValid = () => ({
        isValid: user.outside_number !== "",
        message: "El número exterior es requerido",
    });

    const isColonyValid = () => ({
        isValid: user.colony !== "",
        message: "La colonia del usuario es requerida",
    });

    const isZipCode = () => ({
        isValid: user.zipcode !== "",
        message: "El código postal es obligatorio",
    });

    const isCityValid = () => ({
        isValid: user.address_city_id !== null,
        message: "La ciudad del usuario es requerida",
    });

    /**
     * validacion para el correo
     */
    const isEmailValid = () => {
        var mailformat =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const isEmail = mailformat.test(user.email);

        return {
            isValid: (user.email !== "" && isEmail) || user.email === "",
            message: "Campo requerido o incorrecto",
        };
    };

    const isPasswordValid = () => {
        if (user.password === "") {
            return {
                isValid: false,
                message: "La contraseña es requerida",
            };
        } else if (user.password !== user.password_confirmation) {
            return {
                isValid: false,
                message: "Las contraseñas no coinciden",
            };
        }
        return {
            isValid: true,
            message: "",
        };
    };

    const isStepOneValid = () => {
        return (
            isNameValid().isValid &&
            isLastNameValid().isValid &&
            isCellphoneValid().isValid &&
            isDateBirthValid().isValid &&
            isEmailValid().isValid
        );
    };
    const isRegisterValid = () => {
        return (
            isNameValid().isValid &&
            isLastNameValid().isValid &&
            isCellphoneValid().isValid &&
            isDateBirthValid().isValid &&
            isPasswordValid().isValid &&
            isStreetValid().isValid &&
            isExteriorNumberValid().isValid &&
            isColonyValid().isValid &&
            isZipCode().isValid &&
            isCityValid().isValid &&
            Selectedterms === "1"
        );
    };

    /**
     * Handler para regresar hacia atras
     */

    const onBackButton = () => {
        //routerLink="/login"
        if (currentStep === 1) {
            history.goBack();
        } else {
            setCurrentStep(1);
        }
    };

    const handleNext = () => {
        setCurrentStep(2);
    };

    /**
     * funcion para registar usuario
     */

    const onRegisterUser = () => {
        present({
            message: "Por favor espere",
        });
        dispatch<any>(signup(user))
            .unwrap()
            .then((response: any) => {
                setShowWelcomeModal(true);
            })
            .catch(({ feedback }: any) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                setTimeout(() => {
                    disminss();
                }, 50);
            });
    };

    /**
     * inciar el proces de login
     */
    const onHandlerLoginUser = () => {
        dispatch<any>(
            login({
                username: user.cellphone,
                password: user.password,
            })
        )
            .unwrap()
            .then((response: any) => {
                history.replace("/app/alerts");
            });

        setShowWelcomeModal(false);
    };

    const onGoTerms = () => {
        const openCapacitorSite = async () => {
            await InAppBrowser.create(
                process.env.REACT_APP_WEB_API + "/terminos",
                "_system"
            );
        };

        openCapacitorSite();
    };

    const onChangeLocation = ({ latitude, longitude }: any) => {
        setUser((user: any) => {
            return {
                ...user,
                latitude,
                longitude,
            };
        });
    };

    const fechaMaximaValida = DateTime.now()
        .minus({ years: 18 })
        .toFormat("yyyy-MM-dd");

    return (
        <IonPage className="register-page">
            <LocationPermissionModal
                isOpen={isModalOpen}
                closeModal={() => history.goBack()}
            />
            <IonHeader className="register-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={onBackButton}>
                            <IonIcon slot="icon-only" src={arrowBackButton} />
                        </IonButton>
                    </IonButtons>

                    <IonTitle className="container-title">
                        <div className="flex-row ion-justify-content-center ion-align-items-center">
                            <IonIcon
                                src={userProfile}
                                className="toolbar-icon"
                            />
                            <span className="titles">Registro</span>
                        </div>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="register-page-content ">
                <div className="form-inner-container  ion-padding-horizontal">
                    <div className="titleForm">
                        {currentStep === 1 ? "Datos Personales" : "Ubicación"}
                    </div>

                    {currentStep === 1 && (
                        <div className="seccion1">
                            <IonGrid>
                                {/*names seccion*/}
                                <IonRow>
                                    <IonCol size="12">
                                        <IonInput
                                            name="name"
                                            maxlength={50}
                                            className="names"
                                            labelPlacement="stacked"
                                            placeholder="Nombre(s)"
                                            value={user.name}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Nombre
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*surnames seccion*/}
                                <IonRow>
                                    <IonCol size="6">
                                        <IonInput
                                            name="last_name"
                                            className="surnames"
                                            labelPlacement="stacked"
                                            placeholder="Tu apellido"
                                            maxlength={50}
                                            value={user.last_name}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Apellido Paterno{" "}
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonInput
                                            maxlength={50}
                                            className="surnames"
                                            name="second_last_name"
                                            labelPlacement="stacked"
                                            placeholder="Tu apellido"
                                            value={user.second_last_name}
                                            onIonInput={onHandlerInputChange}
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Apellido Materno
                                                <IonText color="medium">
                                                    (Opcional)
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*phone seccion*/}
                                <IonRow>
                                    <IonCol size="12">
                                        <IonItem className="phone">
                                            <IonInput
                                                name="cellphone"
                                                labelPlacement="stacked"
                                                placeholder="Ingresa el número celular"
                                                value={user.cellphone}
                                                onIonInput={
                                                    onHandlerInputChange
                                                }
                                                type="tel"
                                                required
                                                ref={async (phoneInput) => {
                                                    if (phoneInput) {
                                                        const input =
                                                            await phoneInput.getInputElement();
                                                        phoneMask(input);
                                                    }
                                                }}
                                            >
                                                <div
                                                    className="text-label"
                                                    slot="label"
                                                >
                                                    Teléfono{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={callOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*gender seccion*/}
                                <IonRow className="gender-seccion">
                                    <IonText className="genderTitle">
                                        Género{" "}
                                        <IonText color="medium">
                                            (Opcional)
                                        </IonText>
                                    </IonText>
                                    <IonCol size="12">
                                        <IonRadioGroup
                                            value={
                                                selectedGender || user.gender
                                            }
                                            onIonChange={handleRadioChange}
                                            className="gender-Buttons flex-row ion-justify-content-between ion-align-items.center"
                                        >
                                            <IonRadio
                                                value="1"
                                                className="circle-radio ion-padding-top"
                                                mode="md"
                                            >
                                                <p className="gender">
                                                    Masculino
                                                </p>
                                            </IonRadio>
                                            <IonRadio
                                                value="2"
                                                className="circle-radio ion-padding-top"
                                                mode="md"
                                            >
                                                <p className="gender">
                                                    Femenino
                                                </p>
                                            </IonRadio>
                                            <IonRadio
                                                value="3"
                                                className="circle-radio ion-padding-top"
                                                mode="md"
                                            >
                                                <p className="gender">Otro</p>
                                            </IonRadio>
                                        </IonRadioGroup>
                                    </IonCol>
                                </IonRow>
                                {/*born day seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonItem
                                            className="bornDay"
                                            id="auto-trigger"
                                        >
                                            <IonInput
                                                name="date_birth"
                                                labelPlacement="stacked"
                                                placeholder="DD/MM/YYYY"
                                                onIonInput={
                                                    onHandlerInputChange
                                                }
                                                value={user.date_birth}
                                                required
                                            >
                                                <div
                                                    className="text-label"
                                                    slot="label"
                                                >
                                                    Fecha de Nacimiento{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                                <IonPopover
                                                    trigger="auto-trigger"
                                                    side="top"
                                                    alignment="center"
                                                    keepContentsMounted={true}
                                                >
                                                    <IonDatetime
                                                        size="cover"
                                                        presentation="date"
                                                        preferWheel={true}
                                                        showDefaultButtons={
                                                            true
                                                        }
                                                        doneText="Aceptar"
                                                        cancelText="Cancelar"
                                                        onIonChange={
                                                            handleDateChange
                                                        }
                                                        max={fechaMaximaValida}
                                                    ></IonDatetime>
                                                </IonPopover>
                                            </IonInput>

                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={calendarOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/* email seccion  */}
                                <IonRow>
                                    <IonCol>
                                        <IonItem className="email">
                                            <IonInput
                                                name="email"
                                                labelPlacement="stacked"
                                                placeholder="tumail@gmail.com"
                                                type="email"
                                                value={user.email}
                                                maxlength={50}
                                                onIonInput={
                                                    onHandlerInputChange
                                                }
                                            >
                                                <div
                                                    className="text-label"
                                                    slot="label"
                                                >
                                                    E-mail{" "}
                                                    <IonText color="medium">
                                                        (opcional)
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={mailOutline}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*Button Next seccion*/}
                                <div className="button-register ion-justify-content-center">
                                    <IonButton
                                        className="nextButton"
                                        onClick={handleNext}
                                        disabled={!isStepOneValid()}
                                    >
                                        Siguiente
                                    </IonButton>
                                </div>
                            </IonGrid>
                        </div>
                    )}

                    {/*seccond seccion*/}
                    {currentStep === 2 && (
                        <div className="seccion2">
                            <IonGrid>
                                {/*street seccion*/}
                                <IonRow>
                                    <IonCol className="street">
                                        <IonInput
                                            name="street"
                                            labelPlacement="stacked"
                                            placeholder="Nombre de la calle"
                                            value={user.street}
                                            type="text"
                                            maxlength={50}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Calle
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*numbers seccion*/}
                                <IonRow>
                                    <IonCol size="6">
                                        <IonInput
                                            maxlength={10}
                                            type="text"
                                            className="numbers"
                                            name="outside_number"
                                            label-placement="stacked"
                                            placeholder="Número"
                                            value={user.outside_number}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Núm. Exterior
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonInput
                                            className="numbers"
                                            name="inside_number"
                                            labelPlacement="stacked"
                                            placeholder="Número"
                                            maxlength={10}
                                            type="text"
                                            value={user.inside_number}
                                            onIonInput={onHandlerInputChange}
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Núm. Interior{" "}
                                                <IonText color="medium">
                                                    (opcional)
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*colony seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            type="text"
                                            className="numbers"
                                            name="colony"
                                            maxlength={50}
                                            labelPlacement="stacked"
                                            placeholder="Colonia o Fraccionamiento"
                                            value={user.colony}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Colonia
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>
                                {/*CP seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonInput
                                            type="number"
                                            className="numbers"
                                            name="zipcode"
                                            maxlength={5}
                                            labelPlacement="stacked"
                                            placeholder="C.P"
                                            value={user.zipcode}
                                            onIonInput={onHandlerInputChange}
                                            required
                                            counter={true}
                                        >
                                            <div
                                                className="text-label"
                                                slot="label"
                                            >
                                                Código Postal{" "}
                                                <IonText color="danger">
                                                    *
                                                </IonText>
                                            </div>
                                        </IonInput>
                                    </IonCol>
                                </IonRow>

                                {/*state seccion*/}
                                <IonRow className="states-seccion">
                                    <IonCol
                                        size="12"
                                        className="text-label-dif"
                                    >
                                        Estado{" "}
                                        <IonText color="danger">*</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonItem className="states">
                                            <IonIcon
                                                slot="start"
                                                src={marketInput}
                                            />

                                            <IonSelect
                                                className="states-select"
                                                placeholder="Seleccionar estado"
                                                name="address_city_id"
                                                toggleIcon={chevronDownOutline}
                                                expandedIcon={chevronUpOutline}
                                                interface="action-sheet"
                                                onIonChange={handleStateChange}
                                                compareWith={(
                                                    o1: State,
                                                    o2: State
                                                ) => {
                                                    return o1 && o2
                                                        ? o1.state_id ===
                                                              o2.state_id
                                                        : o1 === o2;
                                                }}
                                                mode="ios"
                                                value={selectedState}
                                            >
                                                {states
                                                    .sort(
                                                        (a: State, b: State) =>
                                                            a.title.localeCompare(
                                                                b.title
                                                            )
                                                    )
                                                    .map((state: State) => (
                                                        <IonSelectOption
                                                            className="seccions"
                                                            key={state.state_id}
                                                            value={state}
                                                        >
                                                            {state.title}
                                                        </IonSelectOption>
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {/*municipalite and city seccion*/}
                                <IonRow>
                                    <IonCol
                                        size="6"
                                        className="ion-padding-top"
                                    >
                                        <div className="text-label-dif">
                                            Municipio
                                            <IonText color="danger"> *</IonText>
                                        </div>
                                        <IonSelect
                                            className="select"
                                            interface="action-sheet"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            placeholder="Selecciona tu Municipio"
                                            onIonChange={
                                                onHandlerMunicipalityChange
                                            }
                                            disabled={!selectedState}
                                            mode="ios"
                                            compareWith={(
                                                o1: Municipality,
                                                o2: Municipality
                                            ) => {
                                                return o1 && o2
                                                    ? o1.municipality_id ===
                                                          o2.municipality_id
                                                    : o1 === o2;
                                            }}
                                            value={selectedMunicipality}
                                        >
                                            {allMunicipalities
                                                .sort(
                                                    (
                                                        a: Municipality,
                                                        b: Municipality
                                                    ) =>
                                                        a.title.localeCompare(
                                                            b.title
                                                        )
                                                )
                                                .map(
                                                    (
                                                        municipality: Municipality
                                                    ) => (
                                                        <IonSelectOption
                                                            key={
                                                                municipality.municipality_id
                                                            }
                                                            value={municipality}
                                                        >
                                                            {municipality.title}
                                                        </IonSelectOption>
                                                    )
                                                )}
                                        </IonSelect>
                                    </IonCol>

                                    {/*city seccion*/}
                                    <IonCol
                                        size="6"
                                        className="ion-padding-top"
                                    >
                                        <div className="text-label-dif">
                                            Ciudad
                                            <IonText color="danger"> *</IonText>
                                        </div>
                                        <IonSelect
                                            className="select"
                                            interface="action-sheet"
                                            toggleIcon={chevronDownOutline}
                                            expandedIcon={chevronUpOutline}
                                            placeholder="Selecciona tu Ciudad"
                                            aria-required
                                            compareWith={(
                                                o1: City,
                                                o2: City
                                            ) => {
                                                return o1 && o2
                                                    ? o1.city_id === o2.city_id
                                                    : o1 === o2;
                                            }}
                                            value={selectedCities}
                                            onIonChange={onHandlerCityChange}
                                            disabled={
                                                !selectedMunicipality ||
                                                !selectedState
                                            }
                                            mode="ios"
                                        >
                                            {allCities
                                                .sort((a: City, b: City) =>
                                                    a.title.localeCompare(
                                                        b.title
                                                    )
                                                )
                                                .map((city: City) => (
                                                    <IonSelectOption
                                                        key={city.city_id}
                                                        value={city}
                                                    >
                                                        {city.title}
                                                    </IonSelectOption>
                                                ))}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                {/*map seccion*/}
                                <IonRow>
                                    <IonCol
                                        size="12"
                                        className="mapText ion-padding-top"
                                    >
                                        <IonText className="mapText ion-padding-top">
                                            Ubicación Geográficamente de tu
                                            domicilio
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <MapComponent
                                            latitude={user.latitude}
                                            longitude={user.longitude}
                                            onChangeLocation={onChangeLocation}
                                        />
                                    </IonCol>
                                </IonRow>
                                {/*passwords seccion*/}
                                <IonRow>
                                    <IonCol>
                                        <IonItem className="pass">
                                            <IonInput
                                                maxlength={100}
                                                name="password"
                                                labelPlacement="stacked"
                                                type={
                                                    showPassword1
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Ingresa una contraseña"
                                                value={user.password}
                                                onIonInput={(e) => {
                                                    onHandlerInputChange(e);
                                                }}
                                                required
                                            >
                                                <div
                                                    className="text-label"
                                                    slot="label"
                                                >
                                                    Password{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={
                                                    showPassword1
                                                        ? eyeOutline
                                                        : eyeOffOutline
                                                }
                                                onClick={
                                                    togglePasswordVisibility1
                                                }
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonItem className="PasswordConfirm">
                                            <IonInput
                                                maxlength={100}
                                                name="password_confirmation"
                                                labelPlacement="stacked"
                                                type={
                                                    showPassword2
                                                        ? "text"
                                                        : "password"
                                                }
                                                placeholder="Ingresa nuevamente la contraseña"
                                                value={
                                                    user.password_confirmation
                                                }
                                                onIonInput={(e) => {
                                                    onHandlerInputChange(e);
                                                }}
                                            >
                                                <div
                                                    className="text-label"
                                                    slot="label"
                                                >
                                                    Confirmar Password{" "}
                                                    <IonText color="danger">
                                                        *
                                                    </IonText>
                                                </div>
                                            </IonInput>
                                            <IonIcon
                                                className="icon"
                                                slot="end"
                                                icon={
                                                    showPassword2
                                                        ? eyeOutline
                                                        : eyeOffOutline
                                                }
                                                onClick={
                                                    togglePasswordVisibility2
                                                }
                                            />
                                        </IonItem>
                                        {user.password ===
                                        user.password_confirmation ? null : (
                                            <IonText color="danger">
                                                Las contraseñas no coinciden.
                                            </IonText>
                                        )}
                                    </IonCol>
                                </IonRow>

                                {/*confirm seccion*/}
                                <IonRow className="ion-padding-top">
                                    <IonCol
                                        size="1"
                                        className="confimation-radio"
                                    >
                                        <IonRadioGroup
                                            value={Selectedterms}
                                            onIonChange={handleRadioTerms}
                                        >
                                            <IonRadio
                                                mode="md"
                                                value="1"
                                                aria-label="Custom checkbox"
                                            />
                                        </IonRadioGroup>
                                    </IonCol>
                                    <IonCol onClick={onGoTerms}>
                                        <IonText className="confimation-text">
                                            <span className="spanconfirm">
                                                {" "}
                                                Acepto términos y condiciones
                                            </span>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                {/*confirm button icon */}
                                <IonRow>
                                    <div className="button-register ion-justify-content-center">
                                        <IonButton
                                            className="finishButton"
                                            onClick={onRegisterUser}
                                            disabled={!isRegisterValid()}
                                        >
                                            Registrarme
                                        </IonButton>
                                    </div>
                                </IonRow>
                            </IonGrid>
                        </div>
                    )}
                </div>
                <div className="footerRegister"></div>

                <WelcomeModal
                    isOpen={showWelcomeModal}
                    onDismiss={onHandlerLoginUser}
                />
            </IonContent>
        </IonPage>
    );
};

export default Register;
