import {
    createAnimation,
    IonCard,
    IonIcon,
    IonText,
    createGesture,
    Animation,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
    IonGrid,
    IonRow,
    IonCol,
    IonTabButton,
    IonFab,
    IonFabButton,
    useIonLoading,
} from "@ionic/react";
import "./PanicButton.scss";
import React, { useEffect, useState } from "react";
import {
    alertCircle,
    business,
    call,
    carSport,
    headset,
    medical,
    removeOutline,
    shield,
} from "ionicons/icons";
import arrowBackButton from "../../assets/icons/arrow-back-button.svg";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { selectAuthenticatedUserId } from "../../redux/slices/auth";
import { useSelector } from "react-redux";
import { useFeedback } from "../../hooks";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CreateEmergenncyIncident } from "../../redux/slices/app";
import Feedback from "../../services/feedback";

const PanicButton: React.FC<any> = () => {
    /**
     * Local state
     */
    const [progress, setProgress] = useState(0); // Estado del progreso
    const [isPressed, setIsPressed] = useState(false); // Para saber si está presionado
    const [showModal, setShowModal] = useState(false); // Controla si el modal está abierto

    /**
     * Hooks
     */
    const [present, dismiss] = useIonLoading();
    const history = useHistory();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const userId = useSelector(selectAuthenticatedUserId);

    /**
     * Categorias de los incidentes
     */

    const categories: any = [
        {
            id: 1,
            icon: medical,
            label: "MÉDICO",
            color: "#638a86",
            subcategorycolor: "#c5d2d0",
            class: "header-one icon-color-custom",
            subcategoryClass: "one",
            subcategories: [
                {
                    id: 101,
                    label: "ACCIDENTES",
                },
                {
                    id: 102,
                    label: "TRAUMÁTICOS",
                },
                {
                    id: 103,
                    label: "CLÍNICOS",
                },
            ],
        },
        {
            id: 2,
            icon: shield,
            label: "PROTECCIÓN",
            color: "#c17d1a",
            subcategorycolor: "#e9ceab",
            class: "header-two",
            subcategoryClass: "two",
            subcategories: [
                {
                    id: 201,
                    label: "AUXILIO",
                },
                {
                    id: 202,
                    label: "MEDIO AMBIENTE",
                },
                {
                    id: 203,
                    label: "INCENDIO",
                },
                {
                    id: 204,
                    label: "RESCATE",
                },
            ],
        },
        {
            id: 3,
            icon: carSport,
            label: "SEGURIDAD",
            color: "#0088a4",
            subcategorycolor: "#a3cedb",
            class: "header-three",
            subcategoryClass: "three",
            subcategories: [
                {
                    id: 301,
                    label: "ABANDONO",
                },
                {
                    id: 302,
                    label: "ACTOS RELACIONADOS CON ARMAS/EXPLOSIVOS",
                },
                {
                    id: 303,
                    label: "ACTOS RELACIONADOS CON LAS VÍAS DE COMUNICACIÓN",
                },
                {
                    id: 304,
                    label: "ACTOS RELACIONADOS CON EL PATRIMONIO",
                },
                {
                    id: 305,
                    label: "ACTOS RELACIONADOS CON LA FAMILIA",
                },
                {
                    id: 306,
                    label: "ACTOS RELACIONADOS CON LA LIBERTAD PERSONAL",
                },
                {
                    id: 307,
                    label: "ACTOS RELACIONADOS CON LA LIBERTAD Y SEGURIDAD SEXUAL",
                },
                {
                    id: 308,
                    label: "ACTOS RELACIONADOS CON LA SEGURIDAD COLECTIVA",
                },
                {
                    id: 309,
                    label: "ACTOS RELACIONADOS CON LA VIDA Y LA INTEGRIDAD PERSONAL",
                },
                {
                    id: 310,
                    label: "ACTOS RELACIONADOS CON OTROS BIENES JURÍDICOS",
                },
                {
                    id: 311,
                    label: "DISTURBIOS Y ALTERACIÓN AL ORDEN PÚBLICO",
                },
            ],
        },
        {
            id: 4,
            icon: business,
            label: "SERVICIOS PÚBLICOS",
            color: "#6f57a5",
            subcategorycolor: "#c8bfdf",
            class: "header-four",
            subcategoryClass: "four",
            subcategories: [
                {
                    id: 401,
                    label: "INFRAESTRUCTURA",
                },
            ],
        },
        {
            id: 5,
            icon: headset,
            label: "ASISTENCIA",
            color: "#589c6e",
            subcategorycolor: "#c4dac8",
            class: "header-six",
            subcategoryClass: "six",
            subcategories: [
                {
                    id: 501,
                    label: "PROTECCIÓN",
                },
                {
                    id: 502,
                    label: "NOTIFICACIÓN DE HALLAZGO",
                },
                {
                    id: 503,
                    label: "APOYO",
                },
            ],
        },
        {
            id: 6,
            icon: alertCircle,
            label: "OTROS SERVICIOS",
            color: "#e68d25",
            subcategorycolor: "#f6d7b4",
            class: "header-five",
            subcategoryClass: "five",
            subcategories: [
                {
                    id: 601,
                    label: "OTROS SERVICIOS PÚBLICOS",
                },
            ],
        },
        /*{
            id: 7,
            icon: "ban",
            label: "IMPROCEDENTES",
            color: "#cb415e",
            subcategorycolor: "#ebbebe",
            class: "header-seven",
            subcategoryClass: 'seven',
            subcategories: [
                {
                    id: 701,
                    label: "IMPROCEDENTES"
                }
            ]
        }*/
    ];

    /**
     * Accion para crear los incidentes en el 911
     */

    const handlerCreateIncident = async (subcategory: any) => {
        console.log("Se hizo clic en la subcategoría:", subcategory);

        // v1/api/users/$userId/emergencyIncidents
        // body: {
        // cellphone
        // description
        // latitude
        // longitude
        // catalog_incident_id
        // media opcional
        //}

        present({
            message: "Creando incidente por favor espere",
        });

        const position = await Geolocation.getCurrentPosition();
        let { latitude, longitude } = position.coords;
        console.log(position);

        dispatch<any>(
            CreateEmergenncyIncident({
                userId: userId,
                data: {
                    catalog_incident_id: subcategory,
                    latitude: latitude,
                    longitude: longitude,
                    description: "Incidente de emergencia",
                    media: "",
                },
            })
        )
            .then((action: any) => {
                console.log(action);
                // const incident = action.payload.incident;
                // history.push(`/app/alerts/${incident.incident_id}/complete`);
                dismiss();
            })
            .catch((err: any) => {
                let message = Feedback.getMessage(err);

                feedbackApp.showFeedback({
                    title: message.title,
                });
            })
            .then(() => {
                dismiss();
            });
    };
    /**
     * Animacion del botton de panico
     */

    useEffect(() => {
        let interval: any;
        if (isPressed) {
            interval = setInterval(() => {
                setProgress((prev) => {
                    if (prev >= 100) {
                        clearInterval(interval);
                        setShowModal(true);
                        setProgress(0);
                        return 100;
                    }
                    return prev + 1;
                });
            }, 15);
        } else {
            setProgress(0);
        }

        return () => clearInterval(interval);
    }, [isPressed]);

    return (
        <>
            <IonFab className="PanicButton">
                <div
                    className="progress-circle"
                    style={{
                        background: `conic-gradient(#5A4689 ${progress}%, #8200e600 ${progress}%) `, // Cambia el ángulo inicial
                    }}>
                    <IonIcon
                        icon={call}
                        style={{
                            position: "absolute",
                            fontSize: "40px",
                            left: "50px",
                            top: "10px",
                            transform: "rotate(190deg)",
                            zIndex: 1,
                            color: "#5A4689",
                        }}></IonIcon>

                    <IonFabButton
                        mode="ios"
                        onTouchStart={() => setIsPressed(true)}
                        onTouchEnd={() => setIsPressed(false)}
                        onMouseDown={() => setIsPressed(true)}
                        onMouseUp={() => setIsPressed(false)}>
                        <IonLabel
                            style={{
                                fontsWeight: "bold",
                                fontSize: "24px",
                            }}>
                            911
                        </IonLabel>
                    </IonFabButton>
                </div>
            </IonFab>
            <IonIcon
                style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    color: "#00ff55",
                }}
                className="callicon"
                icon={call}></IonIcon>
            <IonModal
                showBackdrop={true}
                backdropDismiss={false}
                className="modal-911"
                isOpen={showModal}
                mode="ios">
                <IonHeader>
                    <IonToolbar className="header">
                        <IonTitle className="title">911</IonTitle>
                        <IonButtons slot="start">
                            <IonButton onClick={() => setShowModal(false)}>
                                <IonIcon
                                    slot="icon-only"
                                    icon={arrowBackButton}
                                    className="toolbar"></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="wrapper ion-padding">
                    <IonAccordionGroup>
                        {categories.map((category: any) => (
                            <div key={category.id}>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="2">
                                            <IonIcon
                                                className="Icon-incidents"
                                                size="large"
                                                src={category.icon}
                                                style={{
                                                    color: category.color,
                                                }}
                                            />
                                        </IonCol>
                                        <IonCol
                                            size="10"
                                            className="cat-seccion">
                                            <IonAccordion
                                                toggleIcon={removeOutline}
                                                toggleIconSlot="end"
                                                value={category.id}>
                                                <IonItem
                                                    lines="none"
                                                    className="ion-no-padding"
                                                    slot="header">
                                                    <IonLabel className="cat-label">
                                                        {category.label}
                                                    </IonLabel>
                                                </IonItem>
                                                {category.subcategories.map(
                                                    (subcategory: any) => (
                                                        <div
                                                            key={subcategory.id}
                                                            slot="content">
                                                            <div>
                                                                <IonItem
                                                                    mode="md"
                                                                    lines="none"
                                                                    button
                                                                    onClick={() =>
                                                                        handlerCreateIncident(
                                                                            subcategory.id
                                                                        )
                                                                    }>
                                                                    <IonIcon
                                                                        style={{
                                                                            color: category.subcategorycolor,
                                                                        }}
                                                                        icon={
                                                                            alertCircle
                                                                        }></IonIcon>
                                                                    <IonLabel className="cat-label">
                                                                        {
                                                                            subcategory.label
                                                                        }
                                                                    </IonLabel>
                                                                </IonItem>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </IonAccordion>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        ))}
                    </IonAccordionGroup>
                </IonContent>
            </IonModal>
        </>
    );
};

export default PanicButton;
